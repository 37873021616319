<template>
  <footer class="footer">
    <div class="container">
      <div
        class="footer-top d-md-flex justify-content-between align-items-center py-4 text-center"
      >
        <router-link to="/">
          <img alt="" src="/assets/img/logo.svg" style="height: 60px" />
        </router-link>
        <div class="menu d-md-flex text-center">
          <div class="tel mx-md-3 my-2 my-md-0">
            <a
              class="tel-link d-flex d-md-block justify-content-center"
              href="tel:+78122401731"
            >
              <span>
                <img alt="" src="/assets/img/phone-white.png" />
              </span>
              <span>+7 (812) 240-17-31</span>
            </a>
            <span class="text-white days">Пн-пт: с 10:00 до 19:00 (Мск)</span>
          </div>
          <div class="telegram-link mx-md-3 my-2 my-md-0">
            <a href="https://t.me/profiwin_support">
              <span>
                <img alt="" src="/assets/img/tg-white.png" />
              </span>
              <span>@profiwin_support</span>
            </a>
          </div>
          <div class="telegram-icon mx-3 mt-4 mt-md-0">
            <a href="https://t.me/profiwin_support">
              <span class="icon-circle">
                <img alt="" src="/assets/img/tg-white.png" />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom row py-4">
        <div class="col-md-4 order-3 order-md-1">
          <span class="infoColor">© 2023 profiwin.ru</span>
          <span class="infoColor">Все права защищены</span>
          <span class="infoColor">
            <a href="https://t.me/profiwin_partners">Сотрудничество</a>
          </span>
        </div>
        <div class="col-md-4 text-center order-2">
          <span class="infoColor">Платеж защищен</span>
          <div>
            <img alt="fmir" src="/assets/img/fmir.png " />
            <img alt="flock" src="/assets/img/flock2.png " />
            <img alt="fsecure" src="/assets/img/fsecure.png " />
            <img alt="fpci" src="/assets/img/fpci.png " />
          </div>
          <span class="infoColor">Принимаем к оплате</span>
          <div>
            <img alt="visa" src="/assets/img/fvisa.png " />
            <img alt="fmc" src="/assets/img/fmc.png " />
            <img alt="fmir" src="/assets/img/fmir.png " />
          </div>
        </div>
        <div class="col-md-4 order-1 order-md-3">
          <a class="link" href="assets/docs/contract_pers_data.pdf?v=1"
            >Политика конфиденциальности</a
          >
          <a class="link" href="assets/docs/oferta.pdf?v=2">Договор оферты</a>
          <a class="link" href="assets/docs/dogovor_pod.pdf?v=2"
            >Договор на оформление платной подписки</a
          >
          <router-link class="link" to="/unsub"
            >Отписаться от подписки</router-link
          >
          <router-link class="link" to="/support">Поддержка</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
footer {
  background: #11595c;
}
.footer-top {
  border-bottom: 1px solid #232323;
}
.footer-bottom-info {
  border-top: 1px solid #232323;
}
.infoColor {
  color: white;
  margin-bottom: 7px;
  display: block;
  font-weight: 700;
  font-size: 18px;
}
.row img {
  width: 35px;
  margin: 3px 7px;
}
.link {
  color: white;
  margin: 0 0 10px;
  display: block;
  font-weight: 700;
  font-size: 16px;
}
.bottom-link {
  color: silver !important;
  display: block;
  font-size: 10px;
}
@media screen and (max-width: 768px) {
  .footer-bottom > div {
    text-align: center;
    margin: 5px 0;
  }
}
</style>
<script setup></script>
