<template>
  <q-layout view="hHh Lpr lff">
    <div class="main-layout d-flex flex-column h-100 min-vh-100">
      <AppHeader
        v-if="
          !(
            $route.meta.landing ||
            $route.meta.auth ||
            (user && $route.meta.history)
          )
        "
        ref="header"
      />
      <AppAdminHeader
        v-if="$route.meta.auth || (user && $route.meta.history)"
        ref="header"
      />
      <div class="p-relative h-max">
        <div
          v-if="isLoading"
          :style="{
            'background-color':
              $route.meta.landing ||
              $route.meta.auth ||
              (user && $route.meta.history)
                ? 'white'
                : '#2cb2ac',
          }"
          class="d-flex justify-content-center align-items-center h-500 flex-column loader pos-absolute"
        >
          <q-spinner-hourglass
            :color="
              $route.meta.landing ||
              $route.meta.auth ||
              (user && $route.meta.history)
                ? 'black'
                : 'white'
            "
            size="7em"
          />
          <strong
            :style="{
              color:
                ($route.meta.landing ||
                $route.meta.auth ||
                (user && $route.meta.history)
                  ? 'black'
                  : 'white') + ' !important',
            }"
            >Загрузка...</strong
          >
        </div>
        <main ref="content" class="h-max" style="max-width: 100vw">
          <div class="w-100 h-100">
            <router-view
              :style="{
                background:
                  $route.meta.landing ||
                  $route.meta.auth ||
                  (user && $route.meta.history)
                    ? 'transparent'
                    : '#2cb2ac',
              }"
              class="h-100"
            />
          </div>
        </main>
      </div>
      <AppFooter
        v-if="
          !(
            $route.meta.landing ||
            $route.meta.auth ||
            (user && $route.meta.history)
          )
        "
        ref="footer"
      />
      <AppAdminFooter
        v-if="$route.meta.auth || (user && $route.meta.history)"
        ref="footer"
      />
    </div>
  </q-layout>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppAdminHeader from "@/components/AppAdminHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppAdminFooter from "@/components/AppAdminFooter.vue";
export default {
  name: "AppLayout",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  watch: {
    route() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
  },
  components: {
    AppHeader,
    AppAdminHeader,
    AppFooter,
    AppAdminFooter,
  },
};
</script>
<style scoped>
.p-relative {
  position: relative;
  display: flex;
}
.pos-absolute {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
}
</style>
<style>
input.q-field__native {
  width: 100% !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 110% !important;
}
.container > h5 {
  color: white;
}

a {
  color: white !important;
}

.h-500,
.h-max {
  flex-grow: 1;
}
.h-500 *:not(svg, g, path) {
  color: black !important;
}
.tel-link {
  display: flex;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.6px;
  transition: all 0.1s ease 0s;
}

.orange {
  color: #ff5b14 !important;
}

.blue {
  color: #495a74 !important;
}

.silver {
  color: #8d99a3 !important;
}

.days {
  font-size: 12px;
  display: block;
  font-weight: 700 !important;
  font-size: 13px;
}

.menu {
  font-size: 1.2rem;
  font-weight: 700 !important;
}

.icon-circle {
  background-color: #1e1e1e !important;
  padding: 10px;
  border-radius: 50%;
}

.btn-orange {
  text-align: center;
  background: linear-gradient(45deg, #fd5c25 30%, #fd7c31 100%);
  border-radius: 25px 25px 0 25px;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  padding: 15px 25px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-orange:hover {
  background: linear-gradient(45deg, #ff5e00 30%, #ff5e00 100%);
}

.not-column .d-flex {
  flex-direction: row !important;
}

@media screen and (max-width: 576px) {
  .btn-orange {
    margin-right: 0px;
  }
}
@media screen and (max-width: 1000px) {
  h2 {
    font-size: 2.5rem !important;
  }
}
</style>
