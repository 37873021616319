<template>
  <header class="header pt-3 pb-1">
    <div class="container-lg">
      <div
        class="header-top justify-content-between align-items-center mb-2 d-none d-md-flex"
      >
        <div class="flex items-center gap-3">
          <router-link :to="user.role == 'partner' ? '/earned' : 'cabinet'">
            <img
              alt=""
              class="logo-img"
              src="/assets/img/logo.svg"
              style="height: 56px"
            />
          </router-link>
          <div v-if="user.role != 'partner'">
            <img
              class="cursor-pointer gift-box-partner"
              src="/assets/img/gift-partner.svg"
            />
            <q-menu>
              <q-card flat>
                <q-card-section class="column items-center gap-3">
                  <img
                    src="/assets/img/paribet.png"
                    style="width: auto; height: 64px"
                  />
                  <h6 class="text-bold text-black text-center">
                    Бонус до 7 000 рублей новым<br />
                    пользователям
                  </h6>
                  <a
                    href="https://clicks.affijet.com/click?offer_id=63&partner_id=8221&utm_medium=Affiliate&landing_id=497"
                    style="width: 100%"
                  >
                    <q-btn
                      color="orange-9"
                      style="border-radius: 10px; width: 100%; height: 36px"
                      unelevated
                    >
                      <span class="text-normal"> Получить </span>
                    </q-btn>
                  </a>
                </q-card-section>
              </q-card>
            </q-menu>
          </div>
          <div>
            <img
              class="cursor-pointer"
              src="/assets/img/share.svg"
              @click="isShareOpened = true"
            />
          </div>
        </div>
        <div class="flex gap-3">
          <router-link
            v-if="user.role != 'partner'"
            class="text-black header-link"
            to="/cabinet"
            >Прогнозы</router-link
          >
          <router-link
            v-if="user.role != 'partner'"
            class="text-black header-link"
            to="/history"
            >История прогнозов</router-link
          >
          <router-link
            :to="user.role == 'partner' ? '/earned' : 'partner'"
            class="text-black header-link"
            >Сколько я заработал?</router-link
          >
          <router-link
            :to="user.role == 'partner' ? '/faq-partner' : 'faq'"
            class="text-black header-link"
            >База знаний</router-link
          >
          <router-link
            v-if="user.role != 'partner'"
            class="text-black header-link"
            to="/where"
          >
            Где нас найти?
          </router-link>
          <div
            v-if="
              user && (user.mainRole == 'admin' || user.mainRole == 'manager')
            "
            class="text-black header-link cursor-pointer"
          >
            Админ-панель
            <q-menu>
              <q-card>
                <q-card-section>
                  <div>
                    <div class="column gap-2">
                      <router-link
                        v-if="
                          user &&
                          (user.mainRole == 'admin' ||
                            user.mainRole == 'manager')
                        "
                        class="text-black header-link"
                        to="/forecasts"
                        >Список прогнозов</router-link
                      >
                      <router-link
                        v-if="
                          user &&
                          (user.mainRole == 'admin' ||
                            user.mainRole == 'manager')
                        "
                        class="text-black header-link"
                        to="/users"
                        >Список пользователей</router-link
                      >
                      <router-link
                        v-if="
                          user &&
                          (user.mainRole == 'admin' ||
                            user.mainRole == 'manager')
                        "
                        class="text-black header-link"
                        to="/users-info"
                        >Информация по пользователям</router-link
                      >
                      <router-link
                        v-if="
                          user &&
                          (user.mainRole == 'admin' ||
                            user.mainRole == 'manager')
                        "
                        class="text-black header-link"
                        to="/payment-requests"
                        >Запросы на выплату</router-link
                      >
                      <router-link
                        v-if="user && user.mainRole == 'admin'"
                        class="text-black header-link"
                        to="/commissions"
                        >Комиссии</router-link
                      >
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-menu>
          </div>
        </div>
        <div class="menu d-none d-md-flex items-center gap-1 cursor-pointer">
          <q-icon name="person" size="1.5em" />
          <div>
            <div style="font-size: 14px; margin-bottom: 0">
              {{ user.name || user.email }}
            </div>
            <div style="font-size: 12px; color: rgba(0, 0, 0, 0.5)">
              {{ isPartner ? "Партнёр" : "Пользователь" }}
            </div>
          </div>
          <q-menu>
            <q-card>
              <q-card-section>
                <div>
                  <div class="column gap-2">
                    <router-link class="text-black" to="/profile">
                      <img src="/assets/img/account.svg" />
                      &nbsp; Мой профиль
                    </router-link>
                    <router-link
                      v-if="user.role != 'partner'"
                      class="text-black"
                      to="/subscribe"
                    >
                      <img src="/assets/img/subscribe.svg" />&nbsp; Подключенные
                      услуги
                    </router-link>
                    <router-link class="text-black" to="/requisites">
                      <q-icon name="credit_card_gear" size="1.5em" />&nbsp;
                      Реквизиты
                    </router-link>
                    <router-link class="text-black" to="/payments">
                      <img src="/assets/img/coins.svg" />&nbsp; Финансы
                    </router-link>
                    <router-link class="text-black" to="/notifications">
                      <img src="/assets/img/notification.svg" />&nbsp; Настройки
                      уведомлений
                    </router-link>
                    <button
                      v-if="
                        user.role != 'partner' &&
                        remainingTimeText != 'Время вышло!'
                      "
                      class="gradient-button"
                      @click="$router.push('/payout?promotion=1')"
                    >
                      <b>Подписка за 1 рубль</b><br />Осталось
                      {{ remainingTimeText }} дней(дня)
                    </button>
                    <div class="text-grey-7">Активная роль</div>
                    <div
                      class="justify-between no-wrap rounded-1 flex items-center gap-2"
                    >
                      Пользователь
                      <q-toggle v-model="isPartner" color="orange-9" />
                      Партнёр
                    </div>
                    <div class="text-black cursor-pointer" @click="logout">
                      <img src="/assets/img/exit.svg" />&nbsp; Выход
                    </div>
                  </div>
                </div>
              </q-card-section>
            </q-card>
          </q-menu>
        </div>
      </div>
      <div
        class="header-top justify-content-between align-items-center mb-2 d-md-none"
      >
        <div class="flex items-center justify-between gap-3">
          <button
            class="d-lg-none"
            style="border: none; background: none"
            @click="isOpened = !isOpened"
          >
            <img
              :src="isOpened ? '/assets/img/close.svg' : '/assets/img/menu.svg'"
              class="cursor-pointer"
              style="width: 36px"
            />
          </button>
          <router-link to="/cabinet">
            <img
              alt=""
              class="logo-img"
              src="/assets/img/logo.svg"
              style="height: 56px"
            />
          </router-link>
          <div v-if="user.role != 'partner'">
            <img
              class="cursor-pointer gift-box-partner"
              src="/assets/img/gift-partner.svg"
            />
            <q-menu>
              <q-card flat>
                <q-card-section class="column items-center gap-3">
                  <img
                    src="/assets/img/paribet.png"
                    style="width: auto; height: 64px"
                  />
                  <h6 class="text-bold text-black text-center">
                    Бонус до 7 000 рублей новым<br />
                    пользователям
                  </h6>
                  <a
                    href="https://clicks.affijet.com/click?offer_id=63&partner_id=8221&utm_medium=Affiliate&landing_id=497"
                    style="width: 100%"
                  >
                    <q-btn
                      color="orange-9"
                      style="border-radius: 10px; width: 100%; height: 36px"
                      unelevated
                    >
                      <span class="text-normal"> Получить </span>
                    </q-btn>
                  </a>
                </q-card-section>
              </q-card>
            </q-menu>
          </div>
          <div>
            <img
              class="cursor-pointer"
              src="/assets/img/share.svg"
              @click="isShareOpened = true"
            />
          </div>
        </div>
      </div>
    </div>
    <teleport to="body">
      <q-dialog v-model="isShareOpened">
        <q-card>
          <div class="column no-wrap">
            <div ref="coupon" class="relative-position">
              <img
                src="/assets/share_template.png"
                style="max-width: 370px; width: 100%"
              />
              <div
                style="
                  position: absolute;
                  font-size: 24px;
                  font-weight: 600;
                  color: white;
                  top: 100px;
                  left: 25px;
                "
              >
                Мой результат за<br />
                {{ shareData?.month }}<br />
                <span class="text-positive"
                  >+{{ shareData?.percent.toFixed(2) }}%</span
                >
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 12px;
                  font-size: 18px;
                  left: 15px;
                  font-weight: 600;
                "
              >
                Промокод: {{ coupon?.code }}
              </div>
              <div style="position: absolute; bottom: 0; right: 8px">
                <qrcode-vue
                  :size="64"
                  :value="`https://profiwin.ru/sign-up?user_id=${user.id}`"
                  level="H"
                />
              </div>
            </div>
            <div class="share-list flex mt-2 justify-center">
              <q-card-section class="flex gap-3 justify-center">
                <div class="column items-center gap-2">
                  <div>
                    <q-btn round>
                      <q-icon
                        v-if="webShareApiSupported"
                        name="download"
                        size="1.5em"
                        @click="download"
                      />
                    </q-btn>
                  </div>
                  <span
                    style="
                      font-size: 10px;
                      line-height: 110%;
                      text-align: center;
                    "
                    >Скачать <br />баннер
                  </span>
                </div>
                <div class="column items-center gap-2">
                  <div>
                    <q-btn round>
                      <q-icon
                        color="orange"
                        name="link"
                        size="1.5em"
                        @click="
                          copy(`https://profiwin.ru/sign-up?user_id=${user.id}`)
                        "
                      />
                    </q-btn>
                  </div>
                  <span
                    style="
                      font-size: 10px;
                      line-height: 110%;
                      text-align: center;
                    "
                  >
                    Копировать <br />ссылку
                  </span>
                </div>
                <div class="column items-center gap-2">
                  <div>
                    <q-btn round>
                      <q-icon
                        color="positive"
                        name="content_paste"
                        size="1.5em"
                        @click="copy(coupon.code)"
                      />
                    </q-btn>
                  </div>
                  <span
                    style="
                      font-size: 10px;
                      line-height: 110%;
                      text-align: center;
                    "
                  >
                    Копировать <br />промокод
                  </span>
                </div>
                <div class="column items-center gap-2">
                  <div>
                    <q-btn round>
                      <q-icon
                        v-if="webShareApiSupported"
                        color="primary"
                        name="share"
                        size="1.5em"
                        @click="shareViaWebShare"
                      />
                    </q-btn>
                  </div>
                  <span
                    style="
                      font-size: 10px;
                      line-height: 110%;
                      text-align: center;
                    "
                    >Поделиться<br />ссылкой
                  </span>
                </div>
                <div class="column items-center gap-2">
                  <div>
                    <q-btn round>
                      <q-icon
                        v-if="webShareApiSupported"
                        color="negative"
                        name="image"
                        size="1.5em"
                        @click="sharePicture"
                      />
                      <q-tooltip></q-tooltip>
                    </q-btn>
                  </div>
                  <span
                    style="
                      font-size: 10px;
                      line-height: 110%;
                      text-align: center;
                    "
                    >Поделиться<br />баннером
                  </span>
                </div>
              </q-card-section>
            </div>
          </div>
        </q-card>
      </q-dialog>
      <q-drawer v-if="isOpened" v-model="isOpened" :breakpoint="992">
        <q-scroll-area class="fit">
          <div class="m-3 flex justify-between">
            <img
              alt=""
              class="logo-img"
              src="/assets/img/logo.svg"
              style="height: 56px"
            />
            <button
              class="d-lg-none"
              style="border: none; background: none"
              @click="isOpened = false"
            >
              <img
                class="cursor-pointer"
                src="/assets/img/close.svg"
                style="width: 36px"
              />
            </button>
          </div>
          <q-list class="mt-4">
            <div class="mx-3">
              <router-link
                v-if="user.role != 'partner'"
                class="text-black"
                to="/cabinet"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img
                      src="/assets/icons/forecasts.svg"
                      style="height: 30px"
                    />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Прогнозы
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="user.role != 'partner'"
                class="text-black"
                to="/history"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/icons/history.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    История прогнозов
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                :to="user.role == 'partner' ? '/faq-partner' : 'faq'"
                class="text-black"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/icons/faq.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    База знаний
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="user.role != 'partner'"
                class="text-black"
                to="/where"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/icons/where.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Где нас найти?
                  </q-item-section>
                </q-item>
              </router-link>
            </div>
            <q-separator class="my-2" color="black" />
            <div class="mx-3">
              <router-link
                :to="user.role == 'partner' ? '/earned' : 'partner'"
                class="text-black"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/icons/partner.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    <b> Партнерская программа </b>
                  </q-item-section>
                </q-item>
              </router-link>
            </div>
            <q-separator class="my-2" color="black" />
            <div class="mx-3">
              <div class="text-h7 text-grey-7 ps-4 my-2">Профиль</div>
              <router-link class="text-black" to="/profile">
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/img/account.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Мой профиль
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="user.role != 'partner'"
                class="text-black"
                to="/profile"
              >
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/img/subscribe.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Подключенные услуги
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link class="text-black" to="/payments">
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img src="/assets/img/coins.svg" style="height: 30px" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Финансы
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link class="text-black" to="/requisites">
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <q-icon color="black" name="credit_card_gear" size="2em" />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Реквизиты
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link class="text-black" to="/requisites">
                <q-item v-ripple active clickable>
                  <q-item-section avatar>
                    <img
                      src="/assets/img/notification.svg"
                      style="height: 30px"
                    />
                  </q-item-section>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Настройки уведомлений
                  </q-item-section>
                </q-item>
              </router-link>
            </div>
            <div class="my-2 mx-3">
              <button
                v-if="
                  user.role != 'partner' && remainingTimeText != 'Время вышло!'
                "
                class="gradient-button"
                @click="$router.push('/payout?promotion=1')"
              >
                <b>Подписка за 1 рубль</b><br />Осталось
                {{ remainingTimeText }} дней(дня)
              </button>
            </div>
            <q-separator
              v-if="
                user && (user.mainRole == 'admin' || user.mainRole == 'manager')
              "
              class="my-2"
              color="black"
            />
            <div class="mx-3">
              <div
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-h7 text-grey-7 ps-4 my-2"
              >
                Админская часть
              </div>
              <router-link
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-black"
                to="/forecasts"
              >
                <q-item v-ripple active clickable>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Список прогнозов
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-black"
                to="/users"
              >
                <q-item v-ripple active clickable>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Список пользователей
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-black"
                to="/users-info"
              >
                <q-item v-ripple active clickable>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Информация по пользователям
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-black"
                to="/payment-requests"
              >
                <q-item v-ripple active clickable>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Запросы на выплату
                  </q-item-section>
                </q-item>
              </router-link>
              <router-link
                v-if="
                  user &&
                  (user.mainRole == 'admin' || user.mainRole == 'manager')
                "
                class="text-black"
                to="/commissions"
              >
                <q-item v-ripple active clickable>
                  <q-item-section
                    class="text-black px-0"
                    style="font-size: 16px"
                  >
                    Комиссии
                  </q-item-section>
                </q-item>
              </router-link>
            </div>
            <q-separator class="my-2" color="black" />
            <div class="mx-3">
              <div class="text-h7 text-grey-7 ps-4 my-2">Активная роль</div>
              <div
                class="px-2 justify-between no-wrap rounded-1 flex items-center gap-2 mx-3"
              >
                Пользователь
                <q-toggle v-model="isPartner" color="orange-9" />
                Партнёр
              </div>
            </div>
            <q-separator class="my-2" color="black" />
            <div
              class="cursor-pointer text-negative mx-3 py-2 ps-4 mb-3"
              @click="logout"
            >
              <img src="/assets/icons/logout.svg" style="height: 30px" />&nbsp;
              Выход
            </div>
          </q-list>
        </q-scroll-area>
      </q-drawer>
    </teleport>
  </header>
</template>
<script>
import create from "@/axios";
import QrcodeVue from "qrcode.vue";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      isOpened: false,
      isPartner: false,
      remainingTime: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
      remainingTimeText: "Осталось 5 дней",
      isShareOpened: false,
      coupons: [],
      coupon: null,
      shareData: null,
      axiosPro: create(),
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    webShareApiSupported() {
      return navigator.share;
    },
  },
  methods: {
    download() {
      const element = this.$refs.coupon;
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "coupon.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    copy(data) {
      navigator.clipboard
        .writeText(data)
        .then(() =>
          this.$q.notify({
            message: "Скопировано",
            type: "info",
            group: false,
            position: "bottom-right",
          })
        )
        .catch(function (error) {
          console.error("Error copying text: ", error);
        });
    },
    shareViaWebShare() {
      navigator.share({
        url: `https://profiwin.ru/sign-up?user_id=${this.user.id}`,
      });
      navigator.share({});
    },
    async sharePicture() {
      const element = this.$refs.coupon;
      try {
        const canvas = await html2canvas(element);
        canvas.toBlob((blob) => {
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          if (navigator.share) {
            navigator
              .share({
                files: [file],
              })
              .then(() => {
                console.log("Image shared successfully!");
              })
              .catch((error) => {
                console.error("Error sharing image:", error);
              });
          } else {
            console.warn("Web Share API is not supported in your browser.");
          }
        }, "image/png");
      } catch (error) {
        console.error("Error capturing and sharing the div:", error);
      }
    },
    logout() {
      localStorage.clear();
      this.$router.go();
    },
  },
  watch: {
    isOpened: function () {
      if (this.isOpened) {
        document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    route() {
      this.isOpened = false;
      this.user = JSON.parse(localStorage.getItem("user"));
      this.isPartner = this.user.role == "partner";
    },
    isPartner(v) {
      if (v) {
        this.user.role = "partner";
        this.$router.push("/earned");
      } else {
        this.user.role = "user";
        this.$router.push("/cabinet");
      }
      localStorage.setItem("user", JSON.stringify(this.user));
    },
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.isPartner = user.role == "partner";
    const options = {
      timeZone: "Europe/Moscow",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formatter = new Intl.DateTimeFormat([], options);
    this.remainingTime = formatter.format(this.remainingTime);
    this.remainingTime = new Date(user.created_at);
    this.remainingTime.setDate(this.remainingTime.getDate() + 5);
    this.interval = setInterval(() => {
      const now = new Date();
      const timeDifference = this.remainingTime - now;
      this.remainingTimeText = Math.floor(
        timeDifference / (1000 * 60 * 60 * 24)
      );
      if (timeDifference < 0) {
        clearInterval(this.interval);
        this.remainingTimeText = "Время вышло!";
      }
    }, 1000);
    this.$store.state.isLoading = true;
    this.axiosPro
      .get("/coupons")
      .then((r) => {
        this.coupons = r.data.data.coupons;
        this.coupon = this.coupons[this.coupons.length - 1];
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      });
    this.axiosPro
      .get("/share-data")
      .then((r) => {
        this.shareData = r.data;
      })
      .finally(() => {
        this.$store.state.isLoading = false;
      });
  },
  components: {
    QrcodeVue,
  },
};
</script>
<style>
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  5%,
  15%,
  25% {
    transform: rotate(-15deg);
  }
  10%,
  20%,
  30% {
    transform: rotate(15deg);
  }
  35%,
  100% {
    transform: rotate(0deg);
  }
}
.gift-box-partner {
  animation: shake 5s ease-in-out infinite;
}
.q-drawer {
  width: 100% !important;
}
a.text-black:hover {
  color: black !important;
}
.header-link.active-link,
.header-link.active-link:hover {
  background: linear-gradient(
    79.6deg,
    rgba(253, 92, 37, 1) 31.01346790790558%,
    rgba(253, 124, 49, 1) 100%
  ) !important;
  color: white !important;
}
.nav-drawer a:not(.social-link) {
  width: 250px;
  text-align: center;
}
.q-focus-helper {
  display: none;
}
</style>
<style scoped>
.gradient-button {
  min-width: 0;
  max-width: 100%;
  margin-top: 8px;
  width: 100%;
  color: white;
  padding: 4px 22px;
  border-radius: 8px;
  border: none;
  background: url("../assets/Gradient.png");
  background-size: 100% 100%;
  position: relative;
}
.nav-drawer {
  position: fixed;
  top: 84px;
  left: 0;
  gap: 14px;
  right: 0;
  z-index: 100;
  bottom: 0;
  height: calc(100vh - 84px);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.header-link {
  font-weight: 500 !important;
  transition: all 0.3s;
  padding: 5px 10px;
  border-radius: 10px;
}
.header-link:hover {
  background: linear-gradient(
    79.6deg,
    rgba(253, 92, 37, 0.16) 31.01346790790558%,
    rgba(253, 124, 49, 0.16) 100%
  );
}
.header {
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.5px);
}
.bg-green {
  background-color: #11595c !important;
}
.enter-button {
  border-radius: 25px 25px 0 25px;
  background: linear-gradient(110deg, #33888c 30%, #3ea1a4 80%);
  padding: 10px 30px;
  position: relative;
  top: -10px !important;
  border: none;
}
.menu-list {
  display: flex;
  justify-content: space-between;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 0.8rem;
}
ul li::before {
  content: "\2022";
  color: #ff5b14;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
ul li:hover a {
  color: #ff5b14 !important;
}
ul li {
  margin: 0 10px !important;
}
.none {
  background: none !important;
  border: none !important;
}
.close {
  display: block;
  margin: 5px 0 0 auto !important;
}
.teleg-link,
.sidebar-link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .must-none {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .logo-img {
    height: 100px;
  }
}
</style>
