import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
import ru from "quasar/lang/ru";
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;
// To be used on app.use(Quasar, { ... })
export default {
  lang: ru,
  config: {
    notify: {
      /* look at QuasarConfOptions from the API card */
    },
  },
  plugins: {
    Notify,
    Loading,
  },
};
