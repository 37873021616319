import axios from "axios";
function create() {
  return axios.create({
    baseURL: "/public/api/profiwin",
    // baseURL: "https://profiwin.ru/public/api/profiwin",
    // baseURL: "http://profiwin/public/api/profiwin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
}
export default create;
