import "bootstrap/dist/css/bootstrap.min.css";
import VueApexCharts from "vue3-apexcharts";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "../src/quasar-user-options";
createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).use(VueApexCharts).mount("#app");
