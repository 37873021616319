<template>
  <footer class="footer">
    <div class="container">
      <div
        class="d-flex flex-column flex-md-row justify-content-between align-items-center py-4 text-center"
      >
        <div style="width: 260px">
          <router-link to="/">
            <img alt="" src="/assets/img/logo.svg" style="height: 60px" />
          </router-link>
        </div>
        <div class="flex flex gap-3 justify-center my-3">
          <a href="https://vk.com/profiwin">
            <img src="/assets/img/vk.svg" />
          </a>
          <a href="https://t.me/profiwinru">
            <img src="/assets/img/tg-footer.svg" />
          </a>
          <a href="https://ok.ru/profiwin">
            <img src="/assets/img/ok.svg" />
          </a>
        </div>
        <div class="column items-baseline text-center">
          <div class="tel mx-md-3 my-2 my-md-0">
            <a
              class="tel-link flex gap-2 justify-content-center"
              href="tel:+78122401731"
            >
              <span>
                <img alt="" src="/assets/img/phone-orange.png" />
              </span>
              <div>
                <div class="text-left">+7 (812) 240-17-31</div>
                <div class="days text-left">Пн-пт: с 10:00 до 19:00 (Мск)</div>
              </div>
            </a>
          </div>
          <div class="my-1" />
          <div class="telegram-link mx-md-3 my-2 my-md-0">
            <a href="https://t.me/profiwin_support">
              <span>
                <img alt="" src="/assets/img/tg.png" />
              </span>
              &nbsp;
              <span>@profiwin_support</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.footer {
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}
.footer * {
  color: black;
}
.row img {
  width: 35px;
  margin: 3px 7px;
}
.link {
  color: white;
  margin: 0 0 10px;
  display: block;
  font-weight: 700;
  font-size: 16px;
}
.bottom-link {
  color: silver !important;
  display: block;
  font-size: 10px;
}
@media screen and (max-width: 768px) {
  .footer-bottom > div {
    text-align: center;
    margin: 5px 0;
  }
}
</style>
<script setup></script>
