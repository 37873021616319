import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/landing/:id",
    name: "landing",
    component: () => import("../views/LandingView.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment1",
    name: "moment1",
    component: () => import("../views/landings/Moment1View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment2",
    name: "moment2",
    component: () => import("../views/landings/Moment2View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment3",
    name: "moment3",
    component: () => import("../views/landings/Moment3View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment4",
    name: "moment4",
    component: () => import("../views/landings/Moment4View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment5",
    name: "moment5",
    component: () => import("../views/landings/Moment5View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment6",
    name: "moment6",
    component: () => import("../views/landings/Moment6View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment7",
    name: "moment7",
    component: () => import("../views/landings/Moment7View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment8",
    name: "moment8",
    component: () => import("../views/landings/Moment8View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment9",
    name: "moment9",
    component: () => import("../views/landings/Moment9View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment10",
    name: "moment10",
    component: () => import("../views/landings/Moment10View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/moment11",
    name: "moment11",
    component: () => import("../views/landings/Moment11View.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/profipay1",
    name: "profipay1",
    component: () => import("../views/landings/ProfiPay1.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/profipay2",
    name: "profipay2",
    component: () => import("../views/landings/ProfiPay2.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/profipay3",
    name: "profipay3",
    component: () => import("../views/landings/ProfiPay3.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/profipay4",
    name: "profipay4",
    component: () => import("../views/landings/ProfiPay4.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/profipay5",
    name: "profipay5",
    component: () => import("../views/landings/ProfiPay5.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/big1",
    name: "big1",
    component: () => import("../views/landings/BigLanding1.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/big2",
    name: "big2",
    component: () => import("../views/landings/BigLanding2.vue"),
    meta: { auth: false, landing: true },
  },
  {
    path: "/",
    name: "main",
    component: () => import("../views/MainView.vue"),
    meta: { auth: false },
  },
  {
    path: "/cabinet",
    name: "cabinet",
    component: () => import("../views/CabinetView.vue"),
    meta: { auth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: { auth: true },
  },
  {
    path: "/earned",
    name: "earned",
    component: () => import("../views/EarnedView.vue"),
    meta: { auth: true, partner: true },
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("../views/PartnerView.vue"),
    meta: { auth: true },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/NotificationsView.vue"),
    meta: { auth: true },
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("../views/SubscribeView.vue"),
    meta: { auth: true },
  },
  {
    path: "/requisites",
    name: "requisites",
    component: () => import("../views/RequisitesView.vue"),
    meta: { auth: true },
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/PaymentsView.vue"),
    meta: { auth: true },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FAQView.vue"),
    meta: { auth: true },
  },
  {
    path: "/faq-partner",
    name: "faq-partner",
    component: () => import("../views/FAQPartnerView.vue"),
    meta: { auth: true, partner: true },
  },
  {
    path: "/where",
    name: "where",
    component: () => import("../views/WhereView.vue"),
    meta: { auth: true },
  },
  {
    path: "/forecasts",
    name: "forecasts",
    component: () => import("../views/ForecastsView.vue"),
    meta: { auth: true, admin: true },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/UsersView.vue"),
    meta: { auth: true, admin: true, users: true },
  },
  {
    path: "/users-info",
    name: "users-info",
    component: () => import("../views/UsersInfoView.vue"),
    meta: { auth: true, admin: true, users: true },
  },
  {
    path: "/commissions",
    name: "commissions",
    component: () => import("../views/CommissionsView.vue"),
    meta: { auth: true, admin: true, users: true },
  },
  {
    path: "/payment-requests",
    name: "payment-requests",
    component: () => import("../views/PaymentRequestsView.vue"),
    meta: { auth: true, admin: true, users: true },
  },
  {
    path: "/tariff",
    name: "tariff",
    component: () => import("../views/TariffView.vue"),
    meta: { auth: false },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../views/ReviewsView.vue"),
    meta: { auth: false },
  },
  {
    path: "/firm",
    name: "firm",
    component: () => import("../views/FirmView.vue"),
    meta: { auth: false },
  },
  {
    path: "/learning",
    name: "learning",
    component: () => import("../views/LearningView.vue"),
    meta: { auth: false },
  },
  {
    path: "/contacts",
    name: "contacts",
    component: () => import("../views/ContactsView.vue"),
    meta: { auth: false },
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/HistoryView.vue"),
    meta: { auth: null, history: true },
  },
  {
    path: "/giveaway",
    name: "giveaway",
    component: () => import("../views/GiveAwayView.vue"),
    meta: { auth: false },
  },
  {
    path: "/unsub",
    name: "unsub",
    component: () => import("../views/UnsubView.vue"),
    meta: { auth: false },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: { auth: false },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("../views/RecoveryView.vue"),
    meta: { auth: false },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: { auth: false },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("../views/RegisterPartnerView.vue"),
    meta: { auth: false },
  },
  {
    path: "/reg-key",
    name: "reg_key",
    component: () => import("../views/RegKeyView.vue"),
    meta: { auth: false },
  },
  {
    path: "/result-guarantee",
    name: "result_guarantee",
    component: () => import("../views/ResultGuaranteeView.vue"),
    meta: { auth: false },
  },
  {
    path: "/send-password",
    name: "send-password",
    component: () => import("../views/SendPasswordView.vue"),
    meta: { auth: false },
  },
  {
    path: "/password-info",
    name: "password-info",
    component: () => import("../views/PasswordInfoView.vue"),
    meta: { auth: false },
  },
  {
    path: "/give-access",
    name: "give-access",
    component: () => import("../views/GiveAccessView.vue"),
    meta: { auth: false },
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import("../views/VIPView.vue"),
    meta: { auth: true },
  },
  {
    path: "/after-payment",
    name: "after-payment",
    component: () => import("../views/AfterPaymentView.vue"),
    meta: { auth: true },
  },
  {
    path: "/payout",
    name: "payout",
    component: () => import("../views/PayoutView.vue"),
    meta: { auth: true },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/SupportView.vue"),
    meta: { auth: false },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkExactActiveClass: "active-link",
});
router.beforeEach((to, _, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (to.meta.auth && user != null) {
    if (
      to.meta.users &&
      (user.mainRole == "support" || user.mainRole == "admin")
    )
      next();
    else if (to.name == "forecasts" && user.mainRole == "support")
      next("not-found");
    else if (to.meta.admin) {
      if (
        user.mainRole === "user" ||
        (user.mainRole === "manager" && to.meta.users)
      )
        next("/cabinet");
      else next();
    } else if (to.meta.partner) {
      if (user.mainRole === "user") next("/cabinet");
      else next();
    } else next();
  } else if (!to.meta.auth && !(user != null)) next();
  else if (to.meta.auth && !(user != null)) next("/");
  next();
});
export default router;
