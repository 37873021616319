<template>
  <header class="header bg-green pt-3 pb-1">
    <div class="container-lg">
      <div
        class="header-top d-flex justify-content-between align-items-center mb-2"
      >
        <router-link to="/">
          <img
            alt=""
            class="logo-img"
            src="/assets/img/logo.svg"
            style="height: 80px"
          />
        </router-link>
        <div class="menu d-none d-md-flex">
          <div class="tel mx-1 mx-lg-3">
            <a class="tel-link" href="tel:+78122401731">
              <span class="actions-header__icon">
                <img alt="" src="/assets/img/phone-orange.png" />
              </span>
              <span>+7 (812) 240-17-31</span>
            </a>
            <span class="text-white days">Пн-пт: с 10:00 до 19:00 (Мск)</span>
          </div>
          <div class="telegram-link mx-1 mx-lg-3 must-none">
            <a href="https://t.me/profiwin_support">
              <span>
                <img alt="" src="/assets/img/tg.png" />
              </span>
              <span>@profiwin_support</span>
            </a>
          </div>
          <div class="telegram-icon mx-1 mx-lg-3">
            <a href="https://t.me/profiwin_support">
              <span class="icon-circle">
                <img alt="" src="/assets/img/tg-white.png" />
              </span>
            </a>
          </div>
          <router-link
            v-if="user"
            class="enter-button text-center mx-auto"
            to="/profile"
          >
            <span>
              <q-icon name="person" size="1.2em" />
              Профиль
            </span>
          </router-link>
          <router-link
            v-if="!user"
            class="enter-button mx-1 mx-lg-3"
            to="/register"
          >
            <span class="text-white"> Регистрация </span>
          </router-link>
          <button class="enter-button mx-1 mx-lg-3" @click="in_up()">
            <span class="text-white">
              <q-icon v-if="!user" name="person" size="1.2em" />
              {{ user ? "Выйти" : "Войти" }}
            </span>
          </button>
        </div>
        <button class="none d-lg-none" @click="drawer = true">
          <q-icon color="white" name="menu" size="2em" />
        </button>
      </div>
      <div class="header-menu mt-3 d-none d-lg-block">
        <nav class="menu-link justify-content-center d-flex">
          <ul class="menu-list">
            <li v-if="!user">
              <router-link to="/tariff">Тарифы</router-link>
            </li>
            <li v-if="!user">
              <router-link to="/reviews">Отзывы клиентов</router-link>
            </li>
            <li v-if="!user">
              <router-link to="/firm">Где мне ставить</router-link>
            </li>
            <li v-if="!user">
              <router-link to="/learning">Обучение</router-link>
            </li>
            <li v-if="!user">
              <router-link to="/contacts">Контакты</router-link>
            </li>
            <li v-if="user">
              <router-link to="/cabinet">Прогнозы</router-link>
            </li>
            <li>
              <router-link to="/history">История прогнозов</router-link>
            </li>
            <li v-if="user">
              <router-link to="/partner">Сколько я заработал?</router-link>
            </li>
            <li
              v-if="
                user && (user.mainRole == 'admin' || user.mainRole == 'manager')
              "
            >
              <router-link to="/forecasts">Список прогнозов</router-link>
            </li>
            <li
              v-if="
                user && (user.mainRole == 'admin' || user.role == 'support')
              "
            >
              <router-link to="/users">Список пользователей</router-link>
            </li>
            <li v-if="user && user.mainRole == 'admin'">
              <router-link to="/users-info"
                >Информация по пользователям</router-link
              >
            </li>
            <li v-if="!user">
              <router-link to="/giveaway">Акции</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <q-drawer
      v-model="drawer"
      :width="100"
      bordered
      class="none d-lg-none bg-grey-9"
      overlay
      side="right"
    >
      <q-scroll-area class="fit">
        <div class="p-3">
          <button class="none d-md-none close" @click="drawer = false">
            <q-icon color="white" name="close" size="2em" />
          </button>
          <div class="d-flex flex-column align-items-center">
            <div class="d-md-none">
              <div class="telegram-link my-3 text-center teleg-link">
                <a href="https://t.me/profiwin_support">
                  <span>
                    <img alt="" src="/assets/img/tg.png" />
                  </span>
                  <span class="ms-2">@profiwin_support</span>
                </a>
              </div>
              <div class="tel my-3 text-center">
                <a
                  class="tel-link d-flex justify-content-center"
                  href="tel:+78122401731"
                >
                  <span class="actions-header__icon">
                    <img alt="" src="/assets/img/phone-orange.png" />
                  </span>
                  <span class="mb-2">+7 (812) 240-17-31</span>
                </a>
                <span class="text-white days"
                  >Пн-пт: с 10:00 до 19:00 (Мск)</span
                >
              </div>
              <div v-if="user" class="d-flex justify-content-center">
                <router-link
                  class="enter-button my-3 text-center mx-auto"
                  to="/profile"
                >
                  <span>
                    <q-icon name="person" size="1.5em" />
                    Профиль
                  </span>
                </router-link>
              </div>
              <div class="d-flex justify-content-center">
                <router-link
                  v-if="!user"
                  class="enter-button mx-1 mx-lg-3"
                  to="/register"
                >
                  <span class="text-white"> Регистрация </span>
                </router-link>
              </div>
              <div class="d-flex justify-content-center">
                <button
                  class="enter-button my-3 text-center mx-auto"
                  @click="in_up()"
                >
                  <span class="text-white">
                    <q-icon v-if="!user" name="person" size="1.5em" />
                    {{ user ? "Выйти" : "Войти" }}
                  </span>
                </button>
              </div>
            </div>
            <router-link v-if="!user" class="my-2 sidebar-link" to="/tariff"
              >Тарифы</router-link
            >
            <router-link v-if="!user" class="my-2 sidebar-link" to="/reviews"
              >Отзывы клиентов</router-link
            >
            <router-link v-if="!user" class="my-2 sidebar-link" to="/firm"
              >Где мне ставить</router-link
            >
            <router-link v-if="!user" class="my-2 sidebar-link" to="/learning"
              >Обучение</router-link
            >
            <router-link v-if="!user" class="my-2 sidebar-link" to="/contacts"
              >Контакты</router-link
            >
            <router-link v-if="user" class="my-2 sidebar-link" to="/cabinet"
              >Прогнозы</router-link
            >
            <router-link class="my-2 sidebar-link" to="/history"
              >История прогнозов</router-link
            >
            <router-link v-if="user" class="my-2 sidebar-link" to="/partner">
              Сколько я заработал?
            </router-link>
            <router-link
              v-if="
                user && (user.mainRole == 'manager' || user.mainRole == 'admin')
              "
              class="my-2 sidebar-link"
              to="/forecasts"
            >
              Список прогнозов
            </router-link>
            <router-link
              v-if="
                user && (user.mainRole == 'admin' || user.role == 'support')
              "
              class="my-2 sidebar-link"
              to="/users"
              >Список пользователей</router-link
            >
            <router-link
              v-if="user && user.mainRole == 'admin'"
              class="my-2 sidebar-link"
              to="/users-info"
              >Информция по пользователям</router-link
            >
            <router-link v-if="!user" class="my-2 sidebar-link" to="/giveaway"
              >Акции</router-link
            >
          </div>
        </div>
      </q-scroll-area>
    </q-drawer>
  </header>
</template>
<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      drawer: false,
    };
  },
  computed: {
    route() {
      return this.$route;
    },
  },
  methods: {
    in_up() {
      if (this.user != null) {
        localStorage.clear();
        this.$router.go();
      } else {
        try {
          this.$router.push("/login");
        } catch (error) {
          error;
        }
      }
    },
  },
  watch: {
    route() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
  },
};
</script>
<style>
.q-drawer {
  width: 100% !important;
}
</style>
<style scoped>
.bg-green {
  background-color: #11595c !important;
}
.enter-button {
  border-radius: 25px 25px 0 25px;
  background: linear-gradient(110deg, #33888c 30%, #3ea1a4 80%);
  padding: 10px 30px;
  position: relative;
  top: -10px !important;
  border: none;
}
.menu-list {
  display: flex;
  justify-content: space-between;
  font-weight: 700 !important;
  text-transform: uppercase;
  font-size: 0.8rem;
}
ul li::before {
  content: "\2022";
  color: #ff5b14;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
ul li:hover a {
  color: #ff5b14 !important;
}
ul li {
  margin: 0 10px !important;
}
.none {
  background: none !important;
  border: none !important;
}
.close {
  display: block;
  margin: 5px 0 0 auto !important;
}
.teleg-link,
.sidebar-link {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .must-none {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .logo-img {
    height: 100px;
  }
}
</style>
