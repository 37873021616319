<template>
  <head>
    <link href="https://fonts.googleapis.com" rel="preconnect" />
    <link crossorigin href="https://fonts.gstatic.com" rel="preconnect" />
    <link
      href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Michroma&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
      rel="stylesheet"
    />
  </head>
  <div id="app h-100">
    <div
      v-if="!loaded"
      class="fore-ground d-flex justify-content-center align-items-center flex-column"
    ></div>
    <AppLayout v-if="loaded" />
  </div>
</template>
<script>
import AppLayout from "@/components/AppLayout.vue";
export default {
  name: "App",
  data() {
    return {
      loaded: null,
    };
  },
  mounted() {
    window.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        this.loaded = true;
      }, 500);
    });
  },
  components: {
    AppLayout,
  },
};
</script>
<style src="./style.css" />
<style>
body {
  background-color: white;
}
.fore-ground {
  background: white !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
}
</style>
